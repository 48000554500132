export const environment = {
  production: true
};

/************************** *********/
/******** configuración PROD *******/
/******** **************************/

export const ENV = {
  name: 'PROD',
  baseApi: 'https://apigw.ucchristus.cl',
  servRoute: '/agendaambulatoria-prod',
  profRoute: '/profesionales-prod',
  fileRoute: '/files-prod',
  validarEnrolamiento: 'https://www.miplansaludplus.cl/ServicioMarcaPaciente/validarenrolamientoV2',
  rutaSubirDocumentoPaciente: 'https://miplansaludplus.cl/servicioMarcaPaciente/subirdocumentospacientebase64',
  archivosSiteFinity: 'https://www.ucchristus.cl/docs/default-source/reservamedica',
  urlMarcaWeb: 'https://marca-agenda.ucchristus.cl/api/Marcas',
  idPlanSaludInit: '0b7a577d-6364-4b28-b2ba-a96e00e243ac',
  idPlanSaludAdministrada: '427cd404-170c-4c11-84eb-ad5800d103ab',
  idCentrosNoDisponibles: [],
  idCentroPrioritario: '52e43c90-8ab7-4e34-afcb-a96f0106bbd1',
  mensajeSinCupos: '<h5>Estimado paciente, actualmente no encontramos citas disponibles en el centro de su elección. Intente nueva búsqueda en todos los centros.</h5>',
  idExamenProcedimiento: '97bd5208-60d1-4d6c-9d1e-a96e00ddbc15',
  idLaboratorioClinico: 'baa46005-a0ff-4715-a074-ac4601235504',
  idAreaLaboratorio: '86399d7e-7bd3-4bed-b18a-aca100fc732c',
  apiDerivacionDetalle: {
    username: 'APPAgendaWeb',
    password: 'PRDUsrN7cAnbAg558pD6MscQG6yXmqMTfhb',
    ambiente: 'portalclinico-prd-pri-usw2',
    ruta: 'indicaciones',
    api: 'https://api.ucchristus.io',

  },
  habilitarMarcasWeb: true,
  habilitarListaEsperaConsultas: true,
  habilitarListaEsperaImagenes: true,
  habilitarListaEsperaProcedimientos: false,
  habilitarMensajeDerivacion: false,
  areaConsultaMedica: {
    id: 'aafc27df-882e-413b-bdcd-a96e00ddbc15',
    nombre: 'Consultas'
  },
  donacionBancoDeSangre: {
    idArea: '7822cd9e-4552-4cb8-8313-abd00148111c',
    idEspecialidad: '2ae24ffb-8c57-4943-adcd-abd400f8271c',
    idServicio: '02560c8e-0c28-4e88-9eb4-abd400f8b282'
  },
  idRegion: '6bad9b25-d5df-4565-b5fe-a6f701444053',
  saludIntegral: {
    mostrarEspecialidadCabeceraExtras: [{
      idEspecialidad: '55741061-2813-4bfd-af3b-ad570164f446',
      idServicio: 'd8e192a8-acda-454f-907c-ad5800ff019c',
    }, {
      idEspecialidad: '55741061-2813-4bfd-af3b-ad570164f446',
      idServicio: '4788642c-1fcc-469e-a2b9-ad5800ff2683',
    }]
  },
  idOcultarServicios: [
    'ec043ac3-8050-49bc-8699-ad5800fec14b',
    'd8e192a8-acda-454f-907c-ad5800ff019c',
    '4788642c-1fcc-469e-a2b9-ad5800ff2683',
    'c2419cd5-4238-446b-952c-ad750112c114',
    'a37ac6f8-b032-46e7-8f28-ad7501130f46',
    '40005643-b304-4dde-93ee-ad7501133198'
  ],
  idOcultarEspecialidades: [
    '55741061-2813-4bfd-af3b-ad570164f446',
    '740cd374-9d38-4eb4-aec0-ad7501124ce3'
  ],
  ginecologia: {
    nombre: 'GINECOLOGIA Y OBSTETRICIA',
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '8193f6de-8308-420e-9309-a934015b1999'
  },
  oftalmologia: {
    nombre: 'OFTALMOLOGIA',
    idEspecialidad: '274daf84-5ed6-4e8c-bbf0-a93400e91bd4',
    idServicio: 'a163c23a-285a-4e9a-9e37-a934015b223e'
  },
  planesSaludOcultos: ['427cd404-170c-4c11-84eb-ad5800d103ab', '34f53732-8b00-4f59-8d6e-b045010598bc'],
  tokenAutenticar: 'T3BMdXo5ckdJaXlvY0FJNg==',
  pathAutenticar: '/auth-prod/Autenticar',
  analyticsCode: 'UA-143119471-1',
  gtagCode: 'GTM-M8RKJKB',
  activarSaludIntegral: true,
  bloquearAreaRadiologia:false,
  pathUsuarios:'/auth-prod',
  cantidadMaximaOrdenes : 4,
  apiCargaOrdenes: "https://upload-agenda.ucchristus.cl/api/agendauc/put_file",
  correoExamenesAdjuntos: null,
  urlSitioSantander: 'https://www.ucchristus.cl/seguro-complementario-santander/Home',
  idMensajeSantander: '7584739a-58cd-49bc-a203-b074011edfaf',
  medicinaCabecerAdultos: {
    PRESENCIAL: { id: 'ec043ac3-8050-49bc-8699-ad5800fec14b', detalle: 'MEDICINA DE CABECERA', idEspecialidad: '55741061-2813-4bfd-af3b-ad570164f446' },
    TLM: { id: 'e11c303e-309b-46d4-bc60-ab10011f6ac5', detalle: 'MEDICINA DE CABECERA TLM', idEspecialidad: '55741061-2813-4bfd-af3b-ad570164f446' }
  },
  medicinaCabecerMenores: {
    PRESENCIAL: { id: 'a37ac6f8-b032-46e7-8f28-ad7501130f46', detalle: 'MEDICINA DE CABECERA', idEspecialidad: '740cd374-9d38-4eb4-aec0-ad7501124ce3' },
    TLM: { id: '40005643-b304-4dde-93ee-ad7501133198', detalle: 'MEDICINA DE CABECERA TLM', idEspecialidad: '740cd374-9d38-4eb4-aec0-ad7501124ce3' }
  },
  areasConEncuestas: []
}

export const EspecialidadesDerivaciones = [
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: 'C077673A-F34F-4EBD-BF55-A9A60142B7C6',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Climaterio y Menopausia'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: 'CFFC6DE4-B9B9-465D-A2F3-A98A00F1E20C',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Embarazo Alto Riesgo'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: 'D73DEAE9-B650-4B52-85A5-AC4D00ED61BD',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Embarazo Alto Riesgo TLM'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '16BA1416-2CC9-4CE4-980C-A98A00F1E2D6',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Ginecología'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: 'A6C6D480-C95B-4BED-9781-A98A00F1E27C',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Ginecología Endocrinológica'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '556E1B9F-9D86-466C-BF96-A98A00F1E148',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Ginecología Pediátrica y Adolescente'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '2BD5CA83-0D9C-4877-8EFB-AB9D00CBC695',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Ginecología Pediátrica y Adolescente TLM'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '24922420-11EA-4226-99DE-A99E01458DC8',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Medicina Reproductiva e Infertilidad'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: 'F753935F-DAAF-4983-AD74-A99E01458E4C',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Obstetricia'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '87A29474-2D4F-401B-8A1C-AB8500B75CB0',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Obstetricia TLM'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: 'B144A551-BB1D-4984-BECE-A98A00F1E333',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Oncología'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '88EE4CB2-2AEB-4B90-ADD9-ABE400DDBA82',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Oncología TLM'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '51D39864-3AB8-41E0-8106-A9BA012EC630',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Piso Pélvico'
  },
  {
    idEspecialidad: 'c4749604-d48f-4f59-96cc-a97400d53190',
    idServicio: '45A7582B-4C34-43F9-A421-A99E0127C59E',
    nombre: 'GINECOLOGIA Y OBSTETRICIA - Seguimiento Folicular'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: 'CF42CC82-5943-4439-8933-A98A00F1FF25',
    nombre: 'OFTALMOLOGÍA - Cornea'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: 'E3397EAF-079E-42B9-A470-A98A00F20022',
    nombre: 'OFTALMOLOGÍA - Estrabismo Pediátrico'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: '5CD30FEB-5EB2-44DD-B67F-A99E0127F75A',
    nombre: 'OFTALMOLOGÍA - Estudio de Estrabismo'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: 'CFCA0090-8A92-4B17-AA65-A98A00F1FE8F',
    nombre: 'OFTALMOLOGÍA - General Adultos'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: 'E3E439E3-C937-416C-923A-A98A00F20213',
    nombre: 'OFTALMOLOGÍA - General Pediátrica'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: '2B04E6A9-10C8-4C88-A47C-A98A00F20120',
    nombre: 'OFTALMOLOGÍA - Neurooftalmología'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: 'C10B0026-D94B-472F-A044-A98A00F2019A',
    nombre: 'OFTALMOLOGÍA - Orbita y Plástica'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: '5167B576-D4C3-4CC5-B5F9-A98A00F20322',
    nombre: 'OFTALMOLOGÍA - Retina'
  },
  {
    idEspecialidad: '87e8bd30-2f07-4c49-b379-a97400d804f0',
    idServicio: '46526907-5BA7-48CA-A0CF-AA30015359E9',
    nombre: 'OFTALMOLOGÍA - Uveítis'
  },
];

export const TooltipsAreas = {
  "aafc27df-882e-413b-bdcd-a96e00ddbc15": {
    nombreArea: "Consultas",
    tooltip: "Consultas Médicas y de otros profesionales presenciales en alguno de nuestros centros médicos.",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "e11c303e-309b-46d4-bc60-ab10011f6ac5": {
    nombreArea: "Telemedicina",
    tooltip: "Consultas virtuales médicas y no médicas",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "RIS_IMAGENES": {
    nombreArea: "Radiología e imágenes",
    tooltip: "Imágenes radiológicas, resonancias, scanner, TAC-CT, densitometrías, ecografías y doppler (salvo ginecológicas)",
    mensajeArea : `PARA EXÁMENES DE PET CT, MEDICINA NUCLEAR, PROCEDIMIENTOS MÉDICOS (PUNCIONES Y BIOPSIAS) Y EXÁMENES PEDIÁTRICOS LLAMAR A CALL CENTER 6767000 OPCIÓN 3. <br/> EXÁMENES DE RADIOGRAFÍA SIMPLE NO REQUIEREN AGENDAMIENTO, DIRIGIRSE DIRECTAMENTE A CADA CENTRO.`,
    mensajeAreaActivo: ENV.bloquearAreaRadiologia === false
  },
  "86399d7e-7bd3-4bed-b18a-aca100fc732c": {
    nombreArea: "Exámenes de Laboratorio",
     tooltip: "Procedimientos y exámenes ambulatorios no radiológicos, ecografías ginecológicas",
     mensajeArea : `Para todos los exámenes de sangre y orina (*), puede agendar online o asistir de forma espontánea a cualquiera de nuestras unidades. Si agenda hora, será atendido de forma más rápida, ya que nuestras unidades cuentan con prioridad de llamado en estos casos. Para más información, visite la <a href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras">sección de toma de muestras</a>.
     <br><span class="text-initial">*Exceptuando curva de glucosa e insulina. </span> `, /** Mensaje de Area */
    mensajeAreaActivo: true
  },
  "97bd5208-60d1-4d6c-9d1e-a96e00ddbc15": {
    nombreArea: "Procedimientos Clínicos",
    tooltip: "Procedimientos y exámenes ambulatorios no radiológicos, ecografías ginecológicas",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "7a9d5f62-8899-4a5c-a28a-a9730138ecdd": {
    nombreArea: "Kinesiología",
    tooltip: "Solo evaluaciones kinesiológicas iniciales, para pacientes en tratamiento llamar al 22 676 7000 Opción 1",
    mensajeArea : ``, /** Mensaje de Area */
    mensajeAreaActivo: false
  },
  "7822cd9e-4552-4cb8-8313-abd00148111c": {
    nombreArea: "Banco de Sangre",
    tooltip: "Agendar horas para donación de sangre, tendrá que contestar una encuesta",
    mensajeArea : `La hora debe ser agendada con los datos del donante. Los datos del paciente le serán requeridos al momento de la donación.`, /** Mensaje de Area */
    mensajeAreaActivo: true
  },
  "3f897659-cbf1-429c-8250-acc30103787b": {
    nombreArea: "Vacunatorio",
    tooltip: "Atención por agendamiento y de manera espontánea según stock disponible",
    mensajeArea : `Atención por agendamiento y de manera espontánea según stock disponible`, /** Mensaje de Area */
    mensajeAreaActivo: true
  },
  "99880dee-c605-4c01-a226-a97301252d72" :{
    nombreArea: "Odontología",
    tooltip: "",
    mensajeArea : `Para reservas en el centro Escuela Militar, ingresar a este <a href="https://ff.healthatom.io/jvw3ns">link</a>, o contáctanos al <a href="tel:+56224820000">+562 2482 0000</a>.`, /** Mensaje de Area */
    mensajeAreaActivo: false
  }
}

export const ColoresCentros = {

  ////Centro Médico Marcoleta//
  "a99faed9-9c91-4379-8968-a96e01482275" : "background: #b2d9ed; color:#000;",
  //Clínica San Carlos de Apoquindo //
  "52e43c90-8ab7-4e34-afcb-a96f0106bbd1" : "background: #642e62; color:#fff;",
  //Edificio Médico Alcántara//
  "47094ff8-a538-4bf1-b007-a96f0107e2b3" : "background: #c38fbd; color:#000;",
  //Centro Médico San Joaquín
  "82786810-a7f6-427d-9527-a96f0108eae5" : "background: #a39ed4; color:#000;",
  //Centro Médico Lira 2//
  "14ffd753-43ed-42ea-81af-a96f01063e39" : "background: #b2d9ed; color:#000;",
  //Telemedicina//
  "42956c1a-e109-4d00-9e53-ab0b01226f58" : "background: #7ec977; color:#000;",
  //Banco de Sangre//
  "abc810e8-4d24-4e13-aa8b-aa73016bed76" : "background: #307FE2; color:#000;",
  //Santa Lucía QA//
  "da1b5630-c2fd-402e-bb2f-ae6f0152da8f" : "background: #46A5F1; color:#000;",
  //Escuela Militar//
  "b04603c3-af4f-419b-849e-afa300cced22" : "background: #B65DC7; color:#000;",
  //Providencia//
  "c4d49c7c-38a2-4aea-af37-afa500d3c410" : "background: #FFEE86; color:#000;",
  //UTM Agustinas 0 = 7//
  "b48d4d3e-eaed-4708-a46c-afa500d2e737" : "background: #307FE2; color:#000;",
  //UTM Cantagallo//
  "3893629d-ba3e-4cf0-85dc-afa500f10589" : "background: #307FE2; color:#000;",
 //UTM Cerro el Plomo//
  "3c9fb5eb-6dac-4725-ba72-afa500f1b3a3" : "background: #307FE2; color:#000;",
 //UTM Guarda Vieja//
  "7bc186ba-bc61-4969-a964-afa500f23c3f" : "background: #307FE2; color:#000;",
 //UTM Lo Beltrán//
  "7631bb5a-a44b-407e-88ca-afa500f2d4a4" : "background: #307FE2; color:#000;",
 //UTM Plaza Ñuñoa//
  "2f27d1b0-cf24-4827-8fcc-afa500f3a33f" : "background: #307FE2; color:#000;",
 //UTM Tobalaba//
  "102be0f1-7bab-4135-b865-afa500f44ef0" : "background: #307FE2; color:#000;",
 //UTM Escuela Militar//
  "068e8ad4-063a-4fe6-ba5a-afaf0158df9f" : "background: #307FE2; color:#000;",
  //Centro Medico San Jorge//
  "73acb087-6b63-44ae-b734-a96f010748c1" : "background: #f2c0eb; color:#000;",
  //Centro del Cáncer
  "b76b793a-543d-4ce4-92a5-a974012ef7bf" : "background: #7badcc; color:#000;",
  //Centro Medico Irarrázaval
  "08892649-ef45-424f-883e-a974012fb2dc" : "background: #fddd98; color:#000;",
  //Centro Medico Lira 4
  "a7802055-9802-4213-9341-a988015796b4" : "background: #4482ac; color:#fff;",
  //Centro Medico Lira 5
  "2aa6ef0e-9eab-419c-a030-a98801580cb3" : "background: #935f90; color:#fff;",
  //Centro Medico Lira 6
  "5ba1617e-9f83-4176-8daf-a9880158666e" : "background: #0d568b; color:#fff;",
  //Club Deportivo Universidad Católica
  "07c425da-a723-46d1-9808-a98a00e76416" : "background: #fddd98; color:#000;",
  //Centro Médico Lira Piso 1
  "9f62d4fd-2de0-4ff9-bb92-aa73016a68f6" : "background: #980052; color:#000;",
}

export const OrdenesCentros = [
  "6bad9b25-d5df-4565-b5fe-a6f701444053", /** Región Metropolitana */
  "52E43C90-8AB7-4E34-AFCB-A96F0106BBD1", /** Clínica San Carlos de Apoquindo */
  "DA1B5630-C2FD-402E-BB2F-AE6F0152DA8F", /** Santa Lucía */
  "47094FF8-A538-4BF1-B007-A96F0107E2B3", /** Centro Médico Alcántara */
  "73ACB087-6B63-44AE-B734-A96F010748C1", /** Centro Médico San Jorge */
  "08892649-EF45-424F-883E-A974012FB2DC", /** Centro Médico Irarrázaval */
  "14FFD753-43ED-42EA-81AF-A96F01063E39", /** Centro Médico Lira 2 */
  "A7802055-9802-4213-9341-A988015796B4", /** Centro Médico Lira 4 */
  "2AA6EF0E-9EAB-419C-A030-A98801580CB3", /** Centro Médico Lira Piso 5 */
  "5BA1617E-9F83-4176-8DAF-A9880158666E", /** Lira Piso 6 */
  "82786810-A7F6-427D-9527-A96F0108EAE5", /** Centro Médico San Joaquín */
  "B04603C3-AF4F-419B-849E-AFA300CCED22", /** Escuela Militar */
  "B48D4D3E-EAED-4708-A46C-AFA500D2E737", /** Providencia */
  "A99FAED9-9C91-4379-8968-A96E01482275" /** Centro Médico Marcoleta */,
  "0AD9E0D2-3252-40C9-A7E0-ADDE00DEFF47" /** Rencagua */
]

export const LISTADO_PLANES_SALUD_INTEGRAL = [
  { idPlan: '34f53732-8b00-4f59-8d6e-b045010598bc', nombrePlan : 'Seguro  Santander', isSantander: true  },
  { idPlan: '427cd404-170c-4c11-84eb-ad5800d103ab', nombrePlan : 'Plan Salud Integral' }
]



export const sid = '8080808080808080';
export const sidtime = 7200000 
export const DIAS_MAXIMOS_PROXIMAS_CITAS=30;

export const MensajesEncuestasAreas = {
  "aafc27df-882e-413b-bdcd-a96e00ddbc15": {
    nombreArea: "Consultas",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "e11c303e-309b-46d4-bc60-ab10011f6ac5": {
    nombreArea: "Telemedicina",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "RIS_IMAGENES": {
    nombreArea: "Radiología e imágenes",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "86399d7e-7bd3-4bed-b18a-aca100fc732c": {
    nombreArea: "Exámenes de Laboratorio",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "97bd5208-60d1-4d6c-9d1e-a96e00ddbc15": {
    nombreArea: "Procedimientos Clínicos",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "7a9d5f62-8899-4a5c-a28a-a9730138ecdd": {
    nombreArea: "Kinesiología",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "7822cd9e-4552-4cb8-8313-abd00148111c": {
    nombreArea: "Banco de Sangre",
    mensaje: `
      <p class="nota gb">(*) Si usted tiene entre 65 y 70 años y/o su nacionalidad es Argentina, Colombiana,
          Brasileña, China, Peruana, será evaluado en la entrevista personal que se efectúa en el Banco de Sangre.
      </p>
      <p class="nota gb">
          (*) Para más detalles de los países y ciudades contraindicados por favor presione 
          <a href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/banco-de-sangre" target="_blank">AQUÍ</a>.
      </p> `,
    mensajeActivo: true  
  },
  "3f897659-cbf1-429c-8250-acc30103787b": {
    nombreArea: "Vacunatorio",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "99880dee-c605-4c01-a226-a97301252d72" :{
    nombreArea: "Odontología",
    mensaje: "Sin Info.",
    mensajeActivo: false
  },
  "e90de9f7-f1bd-44fc-bf7b-b0e4012058af": {
    nombreArea: "PAD FONASA",
    mensaje: "Sin Info.",
    mensajeActivo: false,
    mensajesPorServicio: {
      '560d326a-54f8-4cb0-84d4-b0e40141c637' : { //CIRUGÍA BARIÁTRICA
        mensaje: `
        <p class="nota gb">IMC o Índice de masa corporal se calcula como: el peso en kilos dividido por la altura (estatura) al cuadrado (Kg/m2). Ejemplo: 68 ÷ 1,65<sup>2</sup> (2,7225) = 24,98</p>`,
        mensajeActivo: true
      },
      'bfbc5056-1daf-44a7-822f-b0e401429aa5' : { // INESTABILIDAD DE HOMBRO
        mensaje: `
        <p class="nota gb">Si las imágenes no fueron realizadas en UC CHRISTUS, adjuntar informe al final del proceso y llevar las imágenes + informe el día de la atención.</p>`,
        mensajeActivo: true
      },
      '43e5a476-06d1-4c3e-9f58-b0e40142c804' : { // RUPTURA MANGUITO ROTADOR
        mensaje: `
        <p class="nota gb">Si las imágenes no fueron realizadas en UC CHRISTUS, adjuntar informe al final del proceso y llevar las imágenes + informe el día de la atención.</p>`,
        mensajeActivo: true
      }
    }
  }
}

export const MENSAJES_POR_PLAN_PREVISIONAL = [];


export const ACTIVAR_MARCA_ORIGEN_DERIVACION = false;

export const BLOQUEO_LABORATORIO_CLINICO = {
  idArea: '86399d7e-7bd3-4bed-b18a-aca100fc732c', /** Laboratorio Clinico */
  idServicio: '5d4b63d8-5196-46b0-b242-b03c012cd25f', /** Servicio a Domicilio */
  bloqueoActivo: true,
  mensaje: `
  Para agendar tus exámenes a domicilio ingresa directamente <a href="https://domicilios.ucchristus.cl">Aquí</a>
  `,
  mensajeActivo: true
}



export const AGENDA3URL = {
  CALENDARIO: 'https://agenda3.ucchristus.cl/reserva-horas/seleccion/dia'
}